/* Inspirated from  http://www.alessioatzeni.com/wp-content/tutorials/html-css/CSS3-loading-animation-loop/index.html */

.canvas {
  background-color: #1a1e24;
  background-image: linear-gradient(to bottom, rgba(30, 33, 39, 0.8), rgba(26, 29, 35, 1));
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1a1e24;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3B82F6;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2563EB;
}

/* Card animations */
.card-animation {
  transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275), 
              opacity 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275),
              box-shadow 0.3s ease;
}

.card-enter {
  animation: card-fade-in 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 1;
  transform: translateY(0);
}

.card-exit {
  animation: card-fade-out 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

@keyframes card-fade-in {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes card-fade-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-30px);
  }
}

button {
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
  overflow: hidden;
}

button:active {
  transform: scale(0.95);
}

button:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease;
}

button:hover:after {
  opacity: 1;
}

.filter-button {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.filter-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(59, 130, 246, 0.1), rgba(59, 130, 246, 0));
  z-index: -1;
  border-radius: inherit;
}

.filter-button-active {
  background: linear-gradient(135deg, #3B82F6, #2563EB);
  box-shadow: 0 4px 6px -1px rgba(59, 130, 246, 0.2), 0 2px 4px -1px rgba(59, 130, 246, 0.1);
}

.skill-button {
  position: relative;
  overflow: hidden;
}

.skill-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(59, 130, 246, 0.1), rgba(59, 130, 246, 0));
  z-index: -1;
  border-radius: inherit;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.skill-button:hover:before {
  opacity: 1;
}

.skill-button-active {
  background: linear-gradient(135deg, #3B82F6, #2563EB);
  box-shadow: 0 2px 4px -1px rgba(59, 130, 246, 0.2), 0 1px 2px -1px rgba(59, 130, 246, 0.1);
}

.card-gradient {
  background-image: linear-gradient(to bottom, rgba(30, 33, 39, 0.95), rgba(26, 29, 35, 1));
  border-left: 4px solid;
}

.card-blue {
  border-color: #3B82F6;
  box-shadow: 0 4px 6px -1px rgba(59, 130, 246, 0.1), 0 2px 4px -1px rgba(59, 130, 246, 0.06);
}

.card-red {
  border-color: #EF4444;
  box-shadow: 0 4px 6px -1px rgba(239, 68, 68, 0.1), 0 2px 4px -1px rgba(239, 68, 68, 0.06);
}

.ouro {
  position: relative;
  display:inline-block;
  height: 46px;
  width: 46px;
  margin: 1em;
  border-radius: 50%;  
  background: none repeat scroll 0 0 #DDDDDD;
  overflow:hidden;
  box-shadow: 0 0 10px rgba(0,0,0,.1) inset, 0 0 25px rgba(59, 130, 246, 0.2);
}

.ouro:after {
  content: "";
  position: absolute;
  top: 9px; left: 9px;
  display: block;
  height: 28px; width: 28px;
  background: none repeat scroll 0 0 #F2F2F2;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
}
.ouro > span {
  position: absolute;
  height: 100%; width: 50%;
  overflow: hidden;
}
.left  { left:0   }
.right { left:50% }

.anim {
  position: absolute;
  left: 100%; top: 0;
  height: 100%; width: 100%;
  border-radius: 999px;
  background: none repeat scroll 0 0 #3B82F6;
  opacity: 0.8;
  -webkit-animation: ui-spinner-rotate-left 3s infinite;
  animation: ui-spinner-rotate-left 3s infinite;
  -webkit-transform-origin: 0 50% 0;
  transform-origin: 0 50% 0;
}
.left .anim {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.right .anim {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  left: -100%;
  -webkit-transform-origin: 100% 50% 0;
  transform-origin: 100% 50% 0;
}

/* v2 */
.ouro2 .anim {
 -webkit-animation-delay:0;
 animation-delay:0;
}
.ouro2 .right .anim{
 -webkit-animation-delay: 1.5s;
 animation-delay: 1.5s;
}

/* v3 */
.ouro3 .anim {
 -webkit-animation-delay: 0s;
 -webkit-animation-duration:3s;
 -webkit-animation-timing-function: linear;
 animation-delay: 0s;
 animation-duration:3s;
 animation-timing-function: linear;
}
.ouro3 .right .anim{
 -webkit-animation-name: ui-spinner-rotate-right;
 -webkit-animation-delay:0;
 -webkit-animation-delay: 1.5s;
 animation-name: ui-spinner-rotate-right;
 animation-delay:0;
 animation-delay: 1.5s;
}

/* round variation */
.round .ouro:after {display:none }

/* double variation */
.double .ouro:after {
height: 13px; width: 13px;
left: 7px; top: 7px;
border: 10px solid #ddd;
background: transparent;
box-shadow: none;
}

@keyframes ui-spinner-rotate-right{
0%{transform:rotate(0deg)}
25%{transform:rotate(180deg)}
50%{transform:rotate(180deg)}
75%{transform:rotate(360deg)}
100%{transform:rotate(360deg)}
}
@keyframes ui-spinner-rotate-left{
0%{transform:rotate(0deg)}
25%{transform:rotate(0deg)}
50%{transform:rotate(180deg)}
75%{transform:rotate(180deg)}
100%{transform:rotate(360deg)}
}

@-webkit-keyframes ui-spinner-rotate-right{
0%{-webkit-transform:rotate(0deg)}
25%{-webkit-transform:rotate(180deg)}
50%{-webkit-transform:rotate(180deg)}
75%{-webkit-transform:rotate(360deg)}
100%{-webkit-transform:rotate(360deg)}
}
@-webkit-keyframes ui-spinner-rotate-left{
0%{-webkit-transform:rotate(0deg)}
25%{-webkit-transform:rotate(0deg)}
50%{-webkit-transform:rotate(180deg)}
75%{-webkit-transform:rotate(180deg)}
100%{-webkit-transform:rotate(360deg)}
}

/* presentation styles */
html {height: 100%}
body { text-align:center; background: radial-gradient(circle, #fff 0%, #bbb 85%) no-repeat; background: -webkit-radial-gradient(circle, #fff 0%, #bbb 85%) no-repeat; height: 100%; display:table; width:100%}
.block {display: table-cell; vertical-align:middle}
h1, a { margin-top: 1em; font-family: "Open Sans Light", "Open Sans", "Segoe UI", Helvetica, Arial; color: #888; font-weight: lighter;}
.info {margin-top: 25px}
.info a {font-size: 12px; color: #999}
.info br + a {text-decoration:none}
